import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import ResearchSingle from "../../pageTemplates/ResearchSingle";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveImageData from "../dataResolvers/resolveImageData";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicResearchArticles }) => {
  const { uid, first_publication_date, data } = prismicResearchArticles;
  const { image, ...rest } = data;

  const resolved = {
    data: {
      uid,
      date: first_publication_date,
      ...rest,
      image: resolveImageData({ image }),
    },
  };

  return keysToCamel(resolved);
};

const ResearchSingleQuery = ({ data: _data, location }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <ResearchSingle {...data} location={location} />;
};

export default ResearchSingleQuery;

export const query = graphql`
  query($uid: String!) {
    prismicResearchArticles(uid: { eq: $uid }) {
      uid
      data {
        title
        description {
          text
          html
        }
        image {
          url
          dimensions {
            width
            height
          }
          alt
        }
        article_link {
          kind
          url
          target
        }
        publication
      }
    }
  }
`;
