import React from "react";
import qs from "query-string";
import CORLogo from "@svg/logo-footer.svg";
import NotFoundPage from "./404";
import { Text } from "@ui";
import { t } from "@translations";

/* eslint react/prop-types: 0 */

const AuthCallback = ({ location }) => {
  const params = qs.parse(location.search);
  const { ticket } = params;

  if (ticket) {
    return (
      <div className="fixed inset-0 flex flex-col justify-center items-center bg-blue z-max">
        <div className="mb-6">
          <CORLogo />
        </div>
        <Text preset="label" className="text-white capitalize">
          {t("authenticating")}...
        </Text>
      </div>
    );
  }

  return <NotFoundPage />;
};

export default AuthCallback;
