import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useAppState } from "@state/state";
import SVG404 from "@svg/fourOhFour.svg";
import { SearchInput } from "@modules/search/index";
import { use404PageData } from "@staticQueries";
import {
  LessonQuery,
  AssessmentQuery,
  CollectionQuery,
  BlogSingleQuery,
  NewsSingleQuery,
  ResearchSingleQuery,
  VideoSingleQuery,
  PageQuery,
} from "@dynamicQueries";
import {
  Text,
  RichText,
  Button,
  AppLink,
  RandomShapes,
  Section,
  Container,
} from "@ui";
import { t } from "@translations";

/* eslint react/prop-types: 0 */

const NotFoundPage = props => {
  const [loaded, setLoaded] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const { hero, search, cta } = use404PageData();
  const [{ search: _search }, dispatch] = useAppState();

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue-5",
        showSearch: false,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });

    if (window.__PRISMIC_PREVIEW_DATA__) {
      setIsPreview(true);
    } else {
      setLoaded(true);
    }
  }, []);

  const onSearch = () => {
    dispatch({
      type: "setFilters",
      filters: {
        ..._search.filters,
        type: "singles",
      },
    });
    navigate("curriculum#search-top");
  };

  if (isPreview) {
    const data = window.__PRISMIC_PREVIEW_DATA__;
    const { type } = data[Object.keys(data)[0]];

    switch (type) {
      case "lessons":
        return <LessonQuery data={data} {...props} />;
      case "assessments":
        return <AssessmentQuery data={data} {...props} />;
      case "collections":
        return <CollectionQuery data={data} {...props} />;
      case "blog_posts":
        return <BlogSingleQuery data={data} {...props} />;
      case "news_articles":
        return <NewsSingleQuery data={data} {...props} />;
      case "research_articles":
        return <ResearchSingleQuery data={data} {...props} />;
      case "videos":
        return <VideoSingleQuery data={data} {...props} />;
      case "pages":
        return <PageQuery data={data} {...props} />;

      default:
        return (
          <Section className="bg-blue-5">
            <Container className="text-center">
              <Text preset="h3" className="text-white">
                Unable to load preview.
              </Text>
            </Container>
          </Section>
        );
    }
  }

  return (
    <div className="transition-delayed" style={{ opacity: loaded ? 1 : 0 }}>
      <Section className="relative bg-blue-5 rounded-b-lg mb-2">
        <RandomShapes />
        <Container
          size="sm"
          className="relative z-10 flex flex-col items-center justify-center mt-12"
        >
          <div className="w-48 h-24 mb-6">
            <SVG404 />
          </div>

          <Text preset="h1" className="text-white mb-6">
            {hero.heading}
          </Text>

          <RichText
            html={hero.description.html}
            className="text-white text-center mb-8"
          />

          <AppLink to={cta.link.url} target={cta.link.target}>
            <Button preset="white" text={cta.text} />
          </AppLink>
        </Container>
      </Section>

      <Section className="relative bg-gray-1 sm:rounded-lg">
        <Container
          size="sm"
          className="relative z-10 flex flex-col items-center justify-center"
        >
          <Text preset="h2" className="mb-6">
            {search.heading}
          </Text>

          <RichText
            html={search.description.html}
            className="text-center mb-8"
          />

          <SearchInput
            searchOnType="singles"
            onSearch={onSearch}
            activeClass="bg-gray-2"
            className="hidden md:inline-flex text-gray bg-white mr-8"
            showSuggestions={false}
          />

          <Button
            size="sm"
            preset="bordered"
            onClick={onSearch}
            iconName="magnifyingGlass"
            iconClassName="w-3 h-3"
            text={t("Search curriculum")}
            className="md:hidden text-gray"
          />
        </Container>
      </Section>
    </div>
  );
};

export default NotFoundPage;
